import { Link } from 'gatsby'
import React from 'react'
import * as styles from './component-sitemap.module.scss'
import { useLocalePath } from '../../../hooks'
const ComponentSitemap = ({
    title,
    array
}) => {
    return (
        <div>
            <h3 className={styles.componentTitle}>{title}</h3>
            <ul className={styles.linkContainer}>
                {
                    array?.map(({ node }, index) => (
                        <li key={index}>
                            <Link to={useLocalePath(node.slug)}>{node.title}</Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default ComponentSitemap