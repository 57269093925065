import React from 'react'
import SectionSitemap from '../components/SectionSitemap/SectionSitemap';
import * as styles from '../styles/local.module.scss'
import { graphql } from 'gatsby';
import Seo from '../components/Shared/Seo/Seo'
const PageSitemap = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom: {
            seoTitle,
            seoDescription,
            slug
        },
        allContentfulPageCustom: {
            nodes: allLinks
        }
    }
}) => {
    return (
        <div>
            <h1 style={{ display: 'none' }}>{seoTitle}</h1>
            <Seo slug={slug} title={seoTitle} description={seoDescription} lang={pageContext.node_locale} hrefLangs={allLinks} />
            <div className={styles.container}>
                <SectionSitemap />
            </div>
        </div >

    )
}

export const SitemapQuery = graphql`
query sitemapQuery($id: String!, $contentful_id: String!){
    contentfulPageCustom(id: {eq: $id}){
        seoTitle
        seoDescription
        slug
    }
    allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}}){
        nodes {
            slug
            node_locale
        }
    }
}
`

export default PageSitemap