import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import * as styles from './section-sitemap.module.scss'
import { useLocale, useSitemap } from '../../hooks'
import ComponentSitemap from '../Shared/ComponentSitemap/ComponentSitemap'
const SectionSitemap = () => {
    const locale = useLocale()
    const {
        allContentfulPageProduct,
        allContentfulPageProductsCategory,
        allContentfulPageArticle
    } = useStaticQuery(graphql`
        {
          allContentfulPageProduct {
            edges {
              node {
                  node_locale
                slug
                title
              }
            }
          }
          allContentfulPageProductsCategory {
            edges {
              node {
                node_locale
                slug
                title: seoTitle
              }
            }
          }
          allContentfulPageArticle {
            edges {
              node {
                node_locale
                slug
                title: thumbnailTitle
              }
            }
          }
        }
      `)
    let articles = allContentfulPageArticle.edges.filter((edge) => edge.node.node_locale === locale)
    let products = allContentfulPageProduct.edges.filter((edge) => edge.node.node_locale === locale)
    let category = allContentfulPageProductsCategory.edges.filter((edge) => edge.node.node_locale === locale)

    return (
        <div>
            <h2 className={styles.title}>Sitemap</h2>
            <section>

                <div className={styles.sectionContainer}>
                    <div className={styles.sitemapBlock}>
                        <ComponentSitemap title="category" array={category} />
                    </div>
                    <div className={styles.sitemapBlock}>
                        <ComponentSitemap title="Articles" array={articles} />
                    </div>
                    <div className={styles.sitemapBlock}>
                        <ComponentSitemap title="Products" array={products} />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SectionSitemap